import React, { useState, useContext, useEffect } from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

//https://www.npmjs.com/package/react-multi-carousel

import Carousel from 'react-multi-carousel';
//import '../../assets/css/react-multi-carousel.css';
import 'react-multi-carousel/lib/styles.css';
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};
// const items = [
//   {
//     Item1: require('../../assets/img/flags/4x3/ad.svg'),
//     Item2: require('../../assets/img/flags/4x3/ae.svg')
//   }, {
//     Item1: require('../../assets/img/flags/4x3/ag.svg'),
//     Item2: require('../../assets/img/flags/4x3/am.svg')
//   }, {
//     Item1: require('../../assets/img/flags/4x3/ar.svg'),
//     Item2: require('../../assets/img/flags/4x3/at.svg')
//   },
//   {
//     Item1: require('../../assets/img/flags/4x3/bf.svg'),
//     Item2: require('../../assets/img/flags/4x3/bg.svg')
//   }, {
//     Item1: require('../../assets/img/flags/4x3/cd.svg'),
//     Item2: require('../../assets/img/flags/4x3/ca.svg')
//   }, {
//     Item1: require('../../assets/img/flags/4x3/cm.svg'),
//     Item2: require('../../assets/img/flags/4x3/cn.svg')
//   }, {
//     Item1: require('../../assets/img/flags/4x3/cm.svg'),
//     Item2: require('../../assets/img/flags/4x3/cn.svg')
//   }
// ];

export default function CountrySection(props) {

  // let countyCarousel = React.createRef();
  // React.useEffect(() => {
  //   countyCarousel.current.style.width = "100%";
  //   const updateScroll = () => {
  //     if (window.innerWidth > 700) {
  //       countyCarousel.current.style.width = "600px";
  //     }
  //     else {
  //       countyCarousel.current.style.width = "100%";
  //     }
  //   };
  //   window.addEventListener("scroll", updateScroll);
  //   return function cleanup() {
  //     window.removeEventListener("scroll", updateScroll);
  //   };
  // });

  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState({});
  const [slider, setSlider] = React.useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: false });
      var result = await GetAPI(`cms/home-body-3`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          //globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setData(result.data.content);

          if (result.data.content.flag.length > 0) {
            setSlider(result.data.content.flag.map((item, index) => (
              <div key={item.toString()}>
                  <div className="country-box">
                    <img
                      style={{ objectFit: "none" }}
                      alt="Whissh Image"
                      src={item.image}
                    ></img>
                  </div>
                  {/* <div className="country-box">
                    <img
                      style={{ objectFit: "none" }}
                      alt="Whissh Image"
                      src={item.image}
                    ></img>
                  </div> */}
              </div>
            )));
          }
        }
      }
    }
    FnGetCMSContent();
  }, []);

  // const CountryItems = items.map((item) => (
  //   <div key={item.toString()}>
  //     <div className="country-box">
  //       <img
  //         alt="Whissh Image"
  //         src={item.Item1}
  //       ></img>
  //     </div>
  //     <div className="country-box">
  //       <img
  //         alt="Whissh Image"
  //         src={item.Item2}
  //       ></img>
  //     </div>
  //   </div>
  // ));

  return (
    <>
      <div className="cd-section" id="countries">
        <div className="features-3 pt-0">
          <Container>
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="big-heading">Over 300,000 have chosen Whissh as the best Facilities Management: Cleaning and Aircon Services Company in Singapore</h2>
                  <hr className="hr-large"></hr>
                  <span className="description">
                    We are proud to serve  clients from
                </span>
                <div class="countries-section features-8 section-image">
                  {/* <img alt="Office Cleaning Services Singapore | Whissh Clients" class="rounded" src="https://d3m4ievhpgl874.cloudfront.net/International Customers 2.jxr" /> */}
                  <img alt="Office Cleaning Services Singapore | Whissh Clients" class="rounded" src={require("../../assets/img/homepage/International Customers 2.webp")} />
                </div>
                </Col>
            </Row>
            {/* <Row dangerouslySetInnerHTML={{ __html: data.title }}></Row> */}
            <Row style={{ marginTop: "25px" }} className="ml-auto mr-auto">
              <Col className="ml-auto mr-auto text-center" md="6">
                {/* <div className="ml-auto mr-auto"
                   ref={countyCarousel}
                > */}
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={false}
                  arrows={true}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform .3s ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={"mobile"}
                  dotListClass="custom-dot-list-style"
                // itemClass="carousel-item-padding-40-px"
                >
                  {slider}
                </Carousel>
                {/* </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
