import React, { useState, useEffect, useContext } from "react";
import { Container, FormGroup, Modal, Input, Row, Col, ModalHeader, ModalBody } from "reactstrap";

// import ModalPackageDetails from "components/Booking/ModalPackageDetails.js";
// import ModalPackageAddOns from "components/Booking/ModalPackageAddOns.js";
import { Link, useHistory } from "react-router-dom"
import { GlobalContext } from '../../App';
import { GetAPI, PostAPI } from "../../API/APICalling";
import { getData } from "../../utils/localStorage";
import { ReadRate } from "../../utils/common";
import { DateFormat, getGreetingTime, TimeFormat } from "../../utils/common";

function RequestQuotation(props) {
    const globalContext = useContext(GlobalContext);
    const [modal, setModal] = useState(false);
    const [quotedetails, setQuotedetails] = useState([]);
    const [finalAmount, setFinalAmount] = useState(0);
    const [upgrade, setUpgrade] = useState(0);
    const [PackageDetails, setPackageDetails] = useState({});
    const history = useHistory();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        // const session = getData('session');
        if (!props.match.params.refnum) {
            history.push("/");
        }
        else {
            FnGetCartList(props.match.params.refnum);
        }
    }, [])

    const FnGetCartList = async (refnum) => {
        setQuotedetails([]);
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`myrequest/quotedetails/${refnum}`);
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                setQuotedetails(result.data);
                // console.log(result.data)
                setFinalAmount(result.data.reduce((fa, item) => {
                    return fa + (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
                }, 0))
                setUpgrade(result.data.reduce((fa, item) => {
                    return fa || item.upgrade 
                }, false))
            }
        }
    };

    const FnOnSubmit = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await PostAPI(`myrequest/quoteaccept/${session.arnum}?email=${session.email}&quoterefnum=${props.match.params.refnum}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else {
                    globalContext.notifications('success', 'Quotation accepted successfully');
                    // history.push('/my-account/my-request')
                    history.push({
                        pathname: '/service-booking',
                        state: { redirect: "FINALIZE" }
                    })
                }
            }
        }
    };

    const FnOnUpgradeSubmit = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await PostAPI(`myrequest/quoteaccept/${session.arnum}?email=${session.email}&quoterefnum=${props.match.params.refnum}U`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else {
                    globalContext.notifications('success', 'Quotation accepted successfully');
                    // history.push('/my-account/my-request')
                    history.push({
                        pathname: '/service-booking',
                        state: { redirect: "FINALIZE" }
                    })
                }
            }
        }
    };

    // const FnCloseModal = () => {
    //     console.log("FnCloseModal called");
    //     setModal(false);
    // }

    const FnQuotationDetails = async (data) => {
        setPackageDetails(data);
        setModal(true);
    }


    return (
        <>
            <div id="Quotation" className="adjust-navbar-breadcrumb">
                <Container fluid>
                    <Row>
                        <Col className="ml-auto mr-auto pt-10 pb-30" xl="12" lg="12" md="12">
                            <div className="text-center">
                                <h2 className="big-heading">Quotation</h2>
                                <hr className="hr-small"></hr>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="ml-auto mr-auto pt-15 pb-30" xl="9" lg="10" md="12">
                            <div className="card">
                                <div className="card-header text-left title-2">
                                    Service Quotation
                            </div>
                                <React.Fragment>
                                    <div className="card-body p-0-xs">
                                        <div>
                                            <Row>
                                                <Col md="12">
                                                    {window.innerWidth > 576 ?
                                                        <React.Fragment>
                                                            {quotedetails &&
                                                                <React.Fragment>
                                                                    {
                                                                        quotedetails.length < 1 ?
                                                                            <div className="text-center empty-screen">Cart Is Empty</div> :
                                                                            <React.Fragment>
                                                                                <table className="table order-details-table n-bordered">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Service</th>
                                                                                            <th>Description</th>
                                                                                            <th>Qty</th>
                                                                                            <th>Price</th>
                                                                                            <th>Total</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    {
                                                                                        quotedetails.map((item, index) => (
                                                                                            <tbody key={index}>
                                                                                                <tr>
                                                                                                    <td className="text-center">
                                                                                                        <img
                                                                                                            alt="Whissh Image"
                                                                                                            className="product-img"
                                                                                                            src={item.imagefile}
                                                                                                        ></img>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Link to="#"
                                                                                                            className={"btn-link btn-info product-name"}
                                                                                                            onClick={e => {
                                                                                                                e.preventDefault();
                                                                                                                FnQuotationDetails(item);
                                                                                                                // sbContext.ServiceDispatch({ type: 'PACKAGE_DETAILS', payload: item });
                                                                                                            }}>
                                                                                                            {item.matname}
                                                                                                        </Link>
                                                                                                        <p className="product-details">
                                                                                                            {/* Order No: {item.refnum}<br /> */}
                                                                                                            <Link to="#"
                                                                                                                onClick={e => {
                                                                                                                    e.preventDefault();
                                                                                                                    FnQuotationDetails(item);
                                                                                                                }}>
                                                                                                                Click to view item detail<br />
                                                                                                            </Link>
                                                                                                        </p>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Input type="number" className="product-price-text"
                                                                                                            value={item.qty}
                                                                                                            readOnly
                                                                                                        // onChange={e =>
                                                                                                        //     sbContext.BookingDispatch({
                                                                                                        //         type: 'UPDATE_QTY',
                                                                                                        //         guid: item.guid,
                                                                                                        //         field: 'qty',
                                                                                                        //         value: e.currentTarget.value,
                                                                                                        //     })
                                                                                                        // }
                                                                                                        ></Input>
                                                                                                    </td>
                                                                                                    <td className="text-right">
                                                                                                        <b>S$ &nbsp;<span>{(parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0)).toFixed(2)}</span></b>
                                                                                                    </td>
                                                                                                    <td className="text-right">
                                                                                                        <b>
                                                                                                            S$ &nbsp;<span>{((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span>
                                                                                                        </b>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colSpan="3" rowSpan="2">
                                                                                                    </td>
                                                                                                    <td className="title-3 pr-0">
                                                                                                        {/* <span
                                                                                                            className={"btn-plus-minus"}
                                                                                                            onClick={e => {
                                                                                                                e.preventDefault();
                                                                                                                showHide();
                                                                                                            }}>
                                                                                                            <i className={"fa " + changeName()}></i>
                                                                                                        </span> */}
                                                                                                        <span>
                                                                                                            SUB TOTAL
                                                                                                    </span>
                                                                                                    </td>
                                                                                                    <td className="title-3 pt-20 pl-0 text-right">S$ &nbsp;<span>{((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))).toFixed(2)}</span></td>
                                                                                                </tr>

                                                                                                <tr>
                                                                                                    <td>Location Fee</td>
                                                                                                    <td className="text-right">S$ &nbsp;<span>{((parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span></td>
                                                                                                </tr>

                                                                                            </tbody>
                                                                                        ))
                                                                                    }
                                                                                </table>
                                                                            </React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            {quotedetails &&
                                                                <React.Fragment>
                                                                    {
                                                                        quotedetails.length < 1 ?
                                                                            <div className="text-center empty-screen">Cart Is Empty</div> :
                                                                            <React.Fragment>
                                                                                {
                                                                                    quotedetails.map((item, index) => (
                                                                                        <div key={index}>
                                                                                            <div className="card mb-10">
                                                                                                <div className="card-body">
                                                                                                    <Row>
                                                                                                        <Col lg="3" md="3" sm="3" xs="3" className="pr-0 pl-0 pt-5 text-center">
                                                                                                            <img className="product-img"
                                                                                                                alt="Whissh Image"
                                                                                                                src={item.imagefile}
                                                                                                            ></img>
                                                                                                        </Col>
                                                                                                        <Col md="9" sm="9" xs="9" className="pl-5">
                                                                                                            <Link to="#"
                                                                                                                className={"btn-link btn-info product-name"}
                                                                                                                onClick={e => {
                                                                                                                    e.preventDefault();
                                                                                                                    FnQuotationDetails(item);
                                                                                                                }}>
                                                                                                                {item.matname}
                                                                                                            </Link>
                                                                                                            <p className="product-details">
                                                                                                                {/* Order No: {item.refnum}<br /> */}
                                                                                                                <Link to="#"
                                                                                                                    onClick={e => {
                                                                                                                        e.preventDefault();
                                                                                                                        FnQuotationDetails(item);
                                                                                                                    }}>
                                                                                                                    Click to view item detail<br />
                                                                                                                </Link>

                                                                                                                {/* Frequency: Weekly<br /> */}
                                                                                                                <b>S$ &nbsp;<span>{(parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0)).toFixed(2)}</span></b>
                                                                                                            </p>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                                <div style={{ padding: "1.25rem 1.25rem 0.75rem", borderTop: "1px solid rgba(0, 0, 0, .125)" }}>
                                                                                                    <Row>
                                                                                                        <Col xl="6" lg="6" md="6" sm="6" xs="6">
                                                                                                            <FormGroup>
                                                                                                                <Input type="number" placeholder="Quantity here"
                                                                                                                    readOnly={true}
                                                                                                                    className="text-center"
                                                                                                                    value={item.qty}

                                                                                                                ></Input>
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        <Col xl="6" lg="6" md="6" sm="6" xs="6" className="pt-5">
                                                                                                            <div className="title-2">
                                                                                                                <b>S$ &nbsp;<span>{((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span></b>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>

                                                                                                <div style={{ padding: "1.25rem 1.25rem 0rem", borderTop: "1px solid rgba(0, 0, 0, .125)" }}>
                                                                                                    <Row>
                                                                                                        <table className="table order-details-table n-bordered">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th colSpan="2"><p className="title-2">Service Quotation</p></th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <th className="title-3">
                                                                                                                        <span>SUB TOTAL</span>
                                                                                                                    </th>
                                                                                                                    <th className="title-2 text-right">S$ &nbsp;<span>{((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt || 0))).toFixed(2)}</span></th>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Location  Fee</td>
                                                                                                                    <td className="text-right">S$ &nbsp;<span>{((parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span></td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <p className="product-grand-total">Grand Total&nbsp;&nbsp;
                                                        <span className="product-grand-amount">$ &nbsp; {finalAmount.toFixed(2)}</span>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="card-footer text-center pl-0 pr-0">
                                        {/* <p className="description">Disclaimer: We may need to review charges based on your service location.</p> */}
                                        {finalAmount > 0 &&
                                            <button
                                                className={"btn btn-round btn-info"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    FnOnSubmit();
                                                }}>
                                                ACCEPT & ADD TO CART
                                        </button>
                                        }
                                        {upgrade &&
                                            <button
                                                className={"btn btn-round btn-info2"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    FnOnUpgradeSubmit();
                                                }}>
                                                UPGRADE TO 12 SESSIONS
                                        </button>
                                        }
                                        <p className="description text-right">Note: Applicable discount will be reflected in cart.</p>
                                    </div>
                                </React.Fragment>
                            </div>
                        </Col>
                    </Row>
                    {modal ? (
                        <Modal size="xl" isOpen={modal}
                            className="transparent-modal"
                        >
                            <div className="package-details-modal">
                                <ModalHeader toggle={() => setModal(false)}>
                                </ModalHeader>
                                <ModalBody className="p-0">

                                    <div className="card">
                                        <div className="card-header text-left title-2">Booking Information</div>
                                        {!PackageDetails ? <div className="text-center empty-screen">Cart Details Not Available</div> :
                                            <div className="card-body">
                                                <Row>
                                                    <Col md="3" xs="12" sm="12" className="ml-auto mr-auto ">
                                                        <p className="title-3">Package Information</p>
                                                        <hr></hr>
                                                        <div>
                                                            <div className="other-services-card text-center">
                                                                <div className="card-header text-center title-3">
                                                                    {/* Weekly Cleaning */}
                                                                    {PackageDetails.matname}
                                                                </div>

                                                                <div className="m-0 p-20">
                                                                    {
                                                                        PackageDetails.rate ?
                                                                            <React.Fragment>
                                                                                {ReadRate(PackageDetails.rate).beforerate}<br></br>
                                                                                <span className="dollor">$</span>
                                                                                <span className="price">{ReadRate(PackageDetails.rate).rate}</span><br></br>
                                                                                {ReadRate(PackageDetails.rate).afterrate}
                                                                            </React.Fragment>
                                                                            : null
                                                                    }
                                                                </div>
                                                                <hr></hr>
                                                                <div>
                                                                    {PackageDetails.duration} Hours, {PackageDetails.sessionQty} sessions
                                                                 </div>
                                                                <hr className="mb-0"></hr>
                                                                <div>
                                                                    {PackageDetails.frequency}
                                                                </div>
                                                                {/* <hr className="mb-0"></hr>
                                                                <Button type="button" className="btn-round btn btn-info"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        // props.Function.FnSetRenderComponentType("DATE_TIME");
                                                                        //sbContext.BookingDispatch({ type: 'SET_DATA', payload: PackageDetails });
                                                                        //sbContext.ServiceDispatch({ type: 'DATE_TIME' });
                                                                    }}
                                                                >
                                                                    <span className="bookNow">Change</span>
                                                                </Button> */}
                                                            </div>
                                                        </div >

                                                    </Col>
                                                    <Col md="3" xs="12" sm="12" className="ml-auto mr-auto ">
                                                        <p className="title-3">Package add-ons</p>
                                                        <hr></hr>
                                                        <div>

                                                            {/* (Mop, Vaccum, etc.) */}
                                                            {/* {PackageDetails.addon} */}
                                                            {
                                                                PackageDetails.addon &&
                                                                PackageDetails.addon.split("||").map((item, index) => (
                                                                    <div className="description" key={index}>
                                                                        {item}<br />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        {/* <Button type="button" className="btn-round btn btn-info"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                // props.Function.FnSetRenderModalContentType("PACKAGE_ADD_ONS");
                                                                //sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: PackageDetails });
                                                            }}>
                                                            <span className="bookNow">MODIFY ADD ONS</span>
                                                        </Button> */}
                                                    </Col>
                                                    <Col md="3" xs="12" sm="12" className="ml-auto mr-auto ">
                                                        <p className="title-3">Property Information</p>
                                                        <hr></hr>
                                                        <div>
                                                            <b className="text-left">
                                                                Property Tyoe
                                        </b>
                                                            <p className="description">
                                                                {PackageDetails.shiphousetype}({PackageDetails.shipsize} sqft)
                                        </p>
                                                        </div>
                                                        <div>
                                                            <b className="text-left">
                                                                No. of bedroom
                                        </b>
                                                            <p className="description">
                                                                {PackageDetails.shipbedroomQty}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <b className="text-left">
                                                                No. of bathroom
                                            </b>
                                                            <p className="description">
                                                                {PackageDetails.shipbathroomQty}
                                                            </p>
                                                        </div>
                                                        <br />
                                                        <p className="title-3">Date & Time Slot</p>
                                                        <hr></hr>
                                                        {/* <div>
                                                            <b className="text-left">
                                                                Preferred Date
                                            </b>
                                                            <p className="description">
                                                                {DateFormat(PackageDetails.startdate, 'ddd, DD-MMM-YYYY')}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <b className="text-left">
                                                                Preferred Time
                                            </b>
                                                            <p className="description">
                                                                {getGreetingTime(PackageDetails.starttime, PackageDetails.endtime)} {TimeFormat(PackageDetails.starttime)}-{TimeFormat(PackageDetails.endtime)}
                                                            </p>
                                                        </div> */}

                                                        <div>
                                                            <b className="text-left">Schedule </b>
                                                            <p className="description" style={{ whiteSpace: "pre" }}>{PackageDetails.schedule} </p>
                                                        </div>
                                                    </Col>
                                                    <Col md="3" xs="12" sm="12" className="ml-auto mr-auto ">
                                                        <p className="title-3">Contact Information</p>
                                                        <hr></hr>
                                                        <div>
                                                            <b className="text-left">
                                                                Name
                                            </b>
                                                            <p className="description">
                                                                {PackageDetails.shipfirstname}   {PackageDetails.shiplastname}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <b className="text-left">
                                                                Address
                                            </b>
                                                            <p className="description">
                                                                {PackageDetails.shipfulladdr}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <b className="text-left">
                                                                Phone No.
                                            </b>
                                                            <p className="description">
                                                                {PackageDetails.shipphone}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <b className="text-left">
                                                                Email
                                            </b>
                                                            <p className="description">
                                                                {PackageDetails.shipemail}
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </div>
                                </ModalBody>
                            </div>
                        </Modal>
                    ) : null
                    }
                </Container>
            </div>
        </>
    );
}

export default RequestQuotation;