/*eslint-disable*/
import React, { useContext, createRef, useEffect, useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  Nav,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators

} from "reactstrap";
// core components

import { GlobalContext } from '../../App.js';
import * as constant from '../../constant';
import { getLink, getAlt } from '../../utils/common'

// const items = [
//   {
//     src: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//     altText: "header 1",
//     caption: "header 1"
//   },
//   {
//     src: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
//     altText: "header 2",
//     caption: "header 3"
//   },
//   {
//     src: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
//     altText: "header 3",
//     caption: "header 3"
//   }
// ];

function IndexHeader() {
  let pageHeader = createRef();
  const globalContext = useContext(GlobalContext);
  const [isFetching, setIsFetching] = useState(true);
  const [drpData, setDrpData] = useState([]);
  const [slider, setSlider] = useState([]);
  const [sliderLength, setSliderLength] = useState(0);




  // useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  useEffect(() => { console.log(globalContext.commonState.homeheader.slider, "INDEXHEADER.JS") }, [])

  useEffect(() => {
    if (globalContext.commonState.homeheader.slider) {
      setSlider(globalContext.commonState.homeheader.slider);
      setSliderLength(parseInt(globalContext.commonState.homeheader.slider.length) || 0);
    }
  }, [globalContext.commonState.homeheader]);


  useEffect(() => {
    setDrpData(globalContext.commonState.menuitems.map((item, key1) => (
      <UncontrolledDropdown nav inNavbar key={key1.toString()}>
        <DropdownToggle
          caret
          className="btn-round"
          color="default"
          data-toggle="dropdown"
          btn-value={item.svcnum}
        >
          {item.wname}
        </DropdownToggle>
        <DropdownMenu className="sub-menu-container">
          {item.subCategories.map((item2, key2) => (
            <DropdownItem
              href="#pablo"
              onClick={e => e.preventDefault()}
              key={key2.toString()}
            >
              <Row style={{ width: "100%" }} onClick={() => globalContext.headerDispatch({ type: "SUB_MENU", title: item2.wname, subsvcnum: item2.subsvcnum })}>
                <div className="menu-image">
                  <img
                    alt={item2.imagefile}
                    src={item2.directory ? item2.directory : constant.AVTAR}
                  ></img>
                </div>
                <div className="menu-desc">
                  <div className="text-left">
                    <div>
                      <div className="menu-title">{item2.wname}</div>
                      <div className="menu-content">
                        {item2.wdesc}
                      </div>
                      <div className="menu-link">More Info</div>
                    </div>
                  </div>
                </div>
              </Row>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown >
    )))
  }, [globalContext.commonState.menuitems]);



  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === sliderLength - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? sliderLength - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
      {globalContext.commonState.homeheader.slider &&
        <div className="page-header clear-filter" filter-color="white">
          {/* <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/banner-slide-1.jpg") + ")"
          }}
          ref={pageHeader}
        ></div> */}

          <Row className="justify-content-center"
            style={{
              backgroundImage: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg"
            }}

          >
            <Col md="12">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={slider}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}

                />
                {slider.map((item, index) => (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={index}
                    className="home-carousel-inner"
                  >
                    <img src={getLink(item.image)} alt={getAlt(item.image)} />
                    {/* <div className="carousel-caption d-none d-md-block"> */}
                    {/* <div className="carousel-caption">
                    <h5>{item.caption}</h5>
                  </div> */}
                  </CarouselItem>
                ))}

                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
              <div className="home-carousel-caption">
                {/* <h5>{item.caption}</h5> */}
                <div id="menu-dropdown">
                  <div className="title">
                    <div id="navbar-menu">
                      <Navbar className="navbar-default" expand="lg">
                        <Nav className="adjust-menu-space" navbar>
                          {drpData}
                        </Nav>
                      </Navbar>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

        </div>
      }
      {
        !globalContext.commonState.homeheader.slider &&
        <div class="justify-content-center row">
          <div class="col-md-12">
            <div class="carousel slide">
              <ol class="carousel-indicators">
                <li class=""></li>
                <li class="active"></li>
              </ol>
              <div class="carousel-inner">
                <div class="home-carousel-inner carousel-item">
                  <img src="https://d3m4ievhpgl874.cloudfront.net/Home-Low.jxr" alt="Home Cleaning Services | Home" loading="lazy"/>
                </div>
                <div class="home-carousel-inner carousel-item active">
                  <img src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Yacht - Low" alt="Best Cleaning Services Singapore | Yacht" loading="lazy"/>
                </div>
              </div>
              <a class="carousel-control-prev" data-slide="prev" href="#pablo" role="button">
                <i class="now-ui-icons arrows-1_minimal-left"></i>
              </a>
              <a class="carousel-control-next" data-slide="next" href="#pablo" role="button">
                <i class="now-ui-icons arrows-1_minimal-right"></i>
              </a>
            </div>
            <div class="home-carousel-caption">
              <div id="menu-dropdown">
                <div class="title">
                  <div id="navbar-menu">
                    <nav class="navbar-default navbar navbar-expand-lg">
                      <ul class="adjust-menu-space navbar-nav">
                        <li class="dropdown nav-item">
                          <button type="button" data-toggle="dropdown" btn-value="Home Cleaning" aria-haspopup="true" aria-expanded="false" class="btn-round dropdown-toggle btn btn-default">Home Cleaning </button>
                          <div tabindex="-1" role="menu" aria-hidden="true" class="sub-menu-container dropdown-menu">
                            <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                              <div class="row" style={{width: "100%"}}>
                                <div class="menu-image">
                                  <img alt="Cleaning Services | Home Cleaning" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Home Cleaning/1599756079.jpg" loading="lazy"/>
                                </div>
                                <div class="menu-desc">
                                  <div class="text-left">
                                    <div>
                                      <div class="menu-title">Home Weekly Cleaning</div>
                                      <div class="menu-content">Regular housekeeping for home cleaning. 4 hours per session. e.g. Holland Area</div>
                                      <div class="menu-link">More Info</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                            <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                              <div class="row" style={{width: "100%"}}>
                                <div class="menu-image">
                                  <img alt="Office Cleaning Services Singapore | Home Cleaning" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Home Cleaning/1599756053.jpg" loading="lazy"/>
                                </div>
                                <div class="menu-desc">
                                  <div class="text-left">
                                    <div>
                                      <div class="menu-title">Home Deep Cleaning </div>
                                      <div class="menu-content">Occasional demands (e.g.Spring cleaning, move-in, move-out, etc.)</div>
                                      <div class="menu-link">More Info</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default React.memo(IndexHeader);
