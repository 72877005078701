import React, { useEffect, useState, useContext } from "react";

// reactstrap components
import {
  // Card,
  Container,
  Row,
  Col
} from "reactstrap";

import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';


function EasyStepSection() {

  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState('');
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/home-body-1`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          //globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setData(result.data.content)
        }
      }
    }
    FnGetCMSContent();
  }, []);


  return (
    <>

      {/* {data &&
        <div className="cd-section" id="customer" dangerouslySetInnerHTML={{ __html: data.body }}></div>} */}
      {data.body &&
        <div>
          <div class="features-3 pt-0" style={{marginTop: "20px"}}>
            <div class="ml-auto mr-auto col-md-8">
              <h1 class="big-heading">Best Home and Office Cleaning Services in Singapore</h1>
              <h2 class="big-heading">An Eco-Friendly Facilities Management: Cleaning and Aircon Services Company</h2>
              <hr class="hr-large" />
              <span class="description">Whissh prides ourselves on being the 1st and only service provider in the industry to use Eco-Friendly cleaning products for our <a href="https://www.whissh.com.sg/deep-cleaning/">deep cleaning</a> and <a href="https://www.whissh.com.sg/aircon/">aircon services!</a><br></br><br></br>By popular demand, Whissh Eco-Friendly cleaning products are made available for sale as well<br></br><br></br></span>
              <div>
                {/* <img alt="Best Cleaning Services Singapore | Whissh Eco Friendly Cleaning Products" class="rounded" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Whissh_ECO_Friendly_Cleaning_Product2.jpg" loading="lazy" /> */}
                <img alt="Best Cleaning Services Singapore | Whissh Eco Friendly Cleaning Products" class="rounded" src={require("../../assets/img/homepage/Whissh_ECO_Friendly_Cleaning_Product2.webp")} loading="lazy" />
              </div>
            </div>
          </div>
          <div class="EasyStep-3">
            <div class="container">
              <div class="row">
                <div class="ml-auto mr-auto text-center col-md-8">
                  <h2 class="big-heading">How It Works In 3 Easy Steps!</h2>
                  <hr class="hr-large" /><p class="description">Seamless process for on-demand property service</p>
                </div></div><div class="row"><div class="col-md-4"><div class="info info-hover">
                  <div class="icon icon-success icon-circle"><img alt="1" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Step1.png" loading="lazy" />
                  </div>
                  <div class="details">
                    <h3 class="title">Book easily online</h3>
                    <p class="description">Book online with instant confirmation &amp; we'll match you with a trusted, experienced house cleaner.</p>
                  </div>
                </div>
                </div>
                <div class="col-md-4"><div class="info info-hover"><div class="icon icon-primary icon-circle">
                  <img alt="2" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Step2.png" loading="lazy" />
                </div>
                  <div class="details">
                    <h3 class="title">Confirm and pay securely</h3>
                    <p class="description">Select date, time and pay securely online by credit card.</p>
                  </div>
                </div>
                </div>
                <div class="col-md-4"><div class="info info-hover"><div class="icon icon-info icon-circle">
                  <img alt="3" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Step3.png" loading="lazy" />
                </div>
                  <div class="details">
                    <h3 class="title">Get a 5 star expert</h3>
                    <p class="description">Your trusted service crew will arrive at your property on time. Every cleaner is friendly and reliable.</p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="EasyStep-section features-8 section-image">
            {/* <img alt="Cleaning Services | Whissh Cleaning Services" class="rounded" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/1593110879.jxr" loading="lazy" /> */}
            <img alt="Cleaning Services | Whissh Cleaning Services" class="rounded" src={require("../../assets/img/homepage/1593110879.webp")} loading="lazy" />
          </div>
        </div>
      }
    </>
  );
}

export default EasyStepSection;
